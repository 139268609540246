<template>
    <div>
        <ts-page-title
            :title="$t('bookingPickup.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('bookingPickup.pageTitle'),
                    href: '/admin/driver/booking-pickups'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div
                        class="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-mb-3"
                    >
                        <form class="form-horizontal tw-space-y-2">
                            <div class="row">
                                <div class="col-md-6 tw-space-y-3">
                                    <div class="row">
                                        <label
                                            class="col-md-3 form-col-label control-label required"
                                            >{{
                                                $t("bookingPickup.shopName")
                                            }}</label
                                        >
                                        <div class="col-md-7">
                                            <a-select
                                                :disabled="$whois.shop()"
                                                show-search
                                                v-model="model.shop_id"
                                                :placeholder="
                                                    $t('bookingPickup.shopName')
                                                "
                                                style="width: 100%"
                                                :default-active-first-option="
                                                    false
                                                "
                                                :show-arrow="false"
                                                :filter-option="false"
                                                :not-found-content="null"
                                                @search="handleSearch"
                                            >
                                                <a-select-option
                                                    v-for="s in shopList"
                                                    :key="s.value"
                                                    :value="s.value"
                                                >
                                                    {{ s.label }}
                                                </a-select-option>
                                            </a-select>
                                            <div
                                                class="tw-text-red-600"
                                                v-if="errors.has('shop_id')"
                                            >
                                                {{ errors.first("shop_id") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 tw-space-y-3">
                                    <div class="row">
                                        <label
                                            class="col-md-3 form-col-label control-label required"
                                            >{{
                                                $t("bookingPickup.serviceType")
                                            }}</label
                                        >
                                        <div class="col-md-7">
                                            <a-select
                                                v-model="model.service_type_id"
                                                :options="serviceType"
                                                class="tw-w-full"
                                                :placeholder="
                                                    $t(
                                                        'bookingPickup.serviceType'
                                                    )
                                                "
                                            ></a-select>
                                            <div
                                                class="tw-text-red-600 tw-text-xs"
                                                v-if="
                                                    errors.has(
                                                        'service_type_id'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "service_type_id"
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 tw-space-y-3">
                                    <div class="row">
                                        <label
                                            class="col-md-3 form-col-label control-label"
																						:class="$whois.admin() ? 'required' : '' "
                                            >{{
                                                $t("bookingPickup.driverName")
                                            }}</label
                                        >
                                        <div class="col-md-7">
                                            <a-select
                                                show-search
                                                v-model="model.driver_id"
                                                :placeholder="
                                                    $t(
                                                        'bookingPickup.driverName'
                                                    )
                                                "
                                                style="width: 100%"
                                                :default-active-first-option="
                                                    false
                                                "
                                                :show-arrow="false"
                                                :filter-option="false"
                                                :not-found-content="null"
                                                @search="handleDriverSearch"
                                            >
                                                <a-select-option
                                                    v-for="d in driverList"
                                                    :key="d.value"
                                                    :value="d.value"
                                                >
                                                    {{ d.label }}
                                                </a-select-option>
                                            </a-select>
                                            <div
                                                class="tw-text-red-600"
                                                v-if="errors.has('driver_id')"
                                            >
                                                {{ errors.first("driver_id") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 tw-space-y-3">
                                    <div class="row">
                                        <label
                                            class="col-md-3 form-col-label control-label"
                                            >{{
                                                $t(
                                                    "bookingPickup.voucherNumber"
                                                )
                                            }}</label
                                        >
                                        <div class="col-md-7">
                                            <input
                                                v-model.trim="model.voucher_no"
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'bookingPickup.voucherNumber'
                                                    )
                                                "
                                            />
                                            <div
                                                class="tw-text-red-600"
                                                v-if="errors.has('voucher')"
                                            >
                                                {{ errors.first("voucher") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ProductDetail
                        v-model="model"
                        class="tw-mb-3"
                        ref="item_detail"
                    />
                    <div class="row tw-mt-4">
                        <div
                            class="col-md-12 tw-space-x-4 tw-flex tw-justify-end"
                        >
                            <ts-button
                                @click.prevent="
                                    $router.push({ name: 'booking-pickup' })
                                "
                                >{{ $t("cancel") }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="onSaveAddNew"
                                >{{ $t("saveAddNew") }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                @click.prevent="onSave"
                                >{{ $t("save") }}</ts-button
                            >
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import ProductDetail from "./components/product-item-detail.vue";
import { mapActions, mapState } from "vuex";
export default {
    name: "bookingPickupCreate",
    components: {
        ProductDetail
    },
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                voucher_no: null,
                shop_id: undefined,
                driver_id: undefined,
                pickup_request_id: undefined,
                service_type_id: 2,
                booking_detail: []
            }
        };
    },
    computed: {
        ...mapState("driver/bookingPickup", ["formModels", "edit_data"]),
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        },
        driverList() {
            if (!this.formModels.driver) return [];

            return this.formModels.driver.map(el => ({
                value: el.driver_id,
                label: el.driver_name,
                vehicle_type_id: el.vehicle_type_id
            }));
        },
        serviceType() {
            if (!this.formModels.serviceType) return [];

            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en
            }));
        }
    },
    methods: {
        ...mapActions("driver/bookingPickup", [
            "getFormViewData",
            "store",
            "pickupInfo"
        ]),
        async handleSearch(value) {
            this.loading = true;
            await this.getFormViewData({
                index: "shop",
                params: {
                    fnName: "shop",
                    shop_search: value
                }
            });
            this.loading = false;
        },
        async handleDriverSearch(value) {
            this.loading = true;
            await this.getFormViewData({
                index: "driver",
                params: {
                    fnName: "driver",
                    driver_search: value
                }
            });
            this.loading = false;
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "booking-pickup" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                    this.$router.replace(this.$route.path);
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPickupInfo(id) {
            this.pickupInfo(id)
                .then(response => {
                    const data = response.data;
                    this.model.shop_id = data.shop_id;
                    this.model.driver_id = data.driver_pickup_id;
                    this.model.service_type_id = data.service_type_id;
                    this.model.pickup_request_id = data.pickup_request_id;
                    this.model.booking_detail.push({
                        purchase_amount: "",
                        // receiver_name: "",
                        receiver_phone: "",
                        destination: data.destination,
                        // destination_geo_location: "",
                        package_type_id: 1, // small package
                        number_of_package: 1,
                        // packaging_fee: "",
                        extra_fee: 0,
                        // packaging_id: "",
                        vehicle_type_id: 1, // motobike
                        fee_paid_by: 2,
                        zone_id: null
                        // photo: ""
                    });
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.voucher_no = null;
            this.model.shop_id = undefined;
            this.model.driver_id = undefined;
            this.model.pickup_request_id = undefined;
            this.model.service_type_id = 2;
            this.model.booking_detail = [];
            this.$refs.item_detail.addRow();
        }
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.fetchFormView({ params: {}, index: undefined });
            if (to.query.pickup_request_id) {
                await vm.getPickupInfo(to.query.pickup_request_id);
            }
        });
    }
};
</script>
